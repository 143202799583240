import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const showMoreButton = document.querySelector('section#prizes button#show-more');
const moreSection = document.querySelector('section#prizes #prizes-more');
const defaultSection = document.querySelector('section#prizes #prizes-default');

const swiperPrizesElement = document.querySelector('.swiper-prizes');

if (swiperPrizesElement) {
	const swiperPrizes = new Swiper('.swiper-prizes', {
		modules: [Navigation, Pagination, Autoplay],
		loop: false,
		autoHeight: true,
		slidesPerView: 1,
		spaceBetween: 64,
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: '#btn-swiper-prizes-navigation-next',
			prevEl: '#btn-swiper-prizes-navigation-prev',
		},
		pagination: {
			el: '#swiper-prizes-pagination',
			type: 'bullets',
			clickable: true,
		},
		breakpoints: {
			991: {
				slidesPerGroup: 2,
				slidesPerView: 2,
				spaceBetween: 64,
			},
		},
		on: {
			init: () => {
				document.querySelector('#btn-swiper-prizes-navigation-prev').classList.add('d-none');
			},
		},
	});
	swiperPrizes.on('slideChange', () => {
		if (swiperPrizes.isBeginning) {
			document.querySelector('#btn-swiper-prizes-navigation-prev').classList.add('d-none');
		} else {
			document.querySelector('#btn-swiper-prizes-navigation-prev').classList.remove('d-none');
		}
		if (swiperPrizes.isEnd) {
			moreSection.classList.add('d-none');
			defaultSection.classList.remove('d-none');
			window.scrollTo(0, defaultSection.offsetTop - 320);
			swiperPrizes.slideTo(0);
		}
	});
	if (defaultSection && moreSection && showMoreButton) {
		showMoreButton.addEventListener('click', () => {
			moreSection.classList.remove('d-none');
			defaultSection.classList.add('d-none');
			window.scrollTo(0, moreSection.offsetTop - 320);
		});
	}
}

import * as bootstrap from 'bootstrap5';
import { checkFormType } from './form-application';

$('form#form-age-gate input').on('input', function() {
	let regex = new RegExp(/^[0-9]+$/);
	if (!regex.test(this.value)) {
		this.value = this.value.replace(/[^0-9]/g, '');
	}
	if (this.value.length === this.maxLength) {
		let nextInput = this.nextElementSibling;
		if (nextInput !== null) {
			nextInput.focus();
		}
	}
})

$('#form-age-gate').on('submit', function (event) {
	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({
			'event': 'gate'
		});
	}
});

$('.needs-validation').on('submit', function (event) {
	$(this).addClass('was-validated');
	if (!this.checkValidity()) {
		event.preventDefault();
		event.stopImmediatePropagation();
		$(this).trigger('submit-invalid');
	}
});

$.fn.ajaxInvalidFeedback = function(options) {
	return this.each(function() {
		let object = $(this).data('ajaxInvalidFeedback');
		if (typeof options == 'object') {
			$(this).addClass('has-invalid-feedback-ajax').after(`
			<div class='invalid-feedback invalid-feedback-ajax d-block'>${options.error}</div>
			`);
			let destroyHandler = () => $(this).ajaxInvalidFeedback('destroy');
			$(this).on('change input', destroyHandler);
			$(this.form).on('submit', destroyHandler);
			$(this).data('ajaxInvalidFeedback', {destroyHandler});
		} else if (options === 'destroy') {
			$(this).off('change input', object.destroyHandler).removeClass('has-invalid-feedback-ajax').siblings('.invalid-feedback-ajax').remove();
			$(this.form).off('submit', object.destroyHandler);
			$(this).data('ajaxInvalidFeedback', null);
		}
	});
};

export function processFormErrors(errors) {
	for (let fieldName in errors) {
		if (fieldName == '') {
			for (let error of errors[fieldName]) {
				alert(error);
			}
		} else {
			let error = errors[fieldName].join('<br>');
			$(`[name="${fieldName}"]`).ajaxInvalidFeedback({error: error});
		}
	}
}

export function getRecaptcha() {
	let url = new URL(document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]').src);
	let params = new URLSearchParams(url.search);
	return grecaptcha.execute(params.get('render'));
};

const showFormError = (form, error) => {
	let formError = document.querySelector(`${form} .invalid-feedback-form`);
	formError.textContent = error;
	formError.style.display = 'block';
	let submitButton = document.querySelector(`${form} button[type="submit"]`);
	setTimeout(function() {
		submitButton.setAttribute('disabled', 'disabled');
		submitButton.querySelector('.spinner-border').style.display = 'none';
	}, 100);
	setTimeout(function() {
		formError.style.display = 'none';
		submitButton.removeAttribute('disabled');
		submitButton.querySelector('.spinner-border').style.display = null;
	}, 5000);
}

$('.ajax-form').on('submit', async function(event) {
	event.preventDefault();
	$(this.elements).filter('[type=submit]').prop('disabled', true);
	let formData = new FormData(this);
	let formId = `#${this.id}`;
	if ($(this).data('recaptcha')) {
		formData.set('recaptcha', await getRecaptcha());
	}
	try {
		var data = await $.ajax({
			type: 'post',
			url: this.action,
			data: formData,
			processData: false,
			contentType: false,
		});
	} catch (xhr) {
		let errorEvent = $.Event('submit-error');
		$(this).trigger(errorEvent, xhr);
		if (!errorEvent.isDefaultPrevented()) {
			if (xhr.responseJSON && xhr.responseJSON.errors) {
				processFormErrors(xhr.responseJSON.errors);
			} else if (xhr.responseJSON && xhr.responseJSON.error) {
				showFormError(formId, xhr.responseJSON.error);
			} else {
				console.log("Wystąpił nieznany błąd, spróbuj ponownie później.");
			}
		}
		return;
	} finally {
		$(this.elements).filter('[type=submit]').prop('disabled', false);
	}
	let successEvent = $.Event('submit-success');
	$(this).trigger(successEvent, data);
	if (!successEvent.isDefaultPrevented()) {
		this.reset();
		$(this.elements).prop('disabled', false);
		$(this).removeClass('was-validated');
	}
});

const form = {
	code: document.getElementById('form-code'),
	bill: document.getElementById('form-bill'),
	application: document.getElementById('form-application'),
	contact: document.getElementById('form-contact'),
	winner: document.getElementById('form-winner'),
}

function apiPersonalData(dataUser, modal) {
	const container = $(`${modal} #personal-data`);
	container.empty();
	dataUser.code ? container.append(`<li>Twój kod z kapsla lub zawleczki: ${dataUser.code}</li>`) : null;
	dataUser.firstname ? container.append(`<li>Imię: ${dataUser.firstname}</li>`) : null;
	dataUser.lastname ? container.append(`<li>Nazwisko: ${dataUser.lastname}</li>`) : null;
	dataUser.email ? container.append(`<li>Adres e-mail: ${dataUser.email}</li>`) : null;
	dataUser.phone ? container.append(`<li>Telefon: ${dataUser.phone}</li>`) : null;
	dataUser.street ? container.append(`<li>Adres: ${dataUser.street}</li>`) : null;
	dataUser.streetNr ? container.append(`<li>Numer domu: ${dataUser.streetNr}</li>`) : null;
	dataUser.apartmentNr ? container.append(`<li>Numer lokalu: ${dataUser.apartmentNr}</li>`) : null;
	dataUser.city ? container.append(`<li>Miejscowość: ${dataUser.city}</li>`) : null;
	dataUser.zipCode ? container.append(`<li>Kod pocztowy: ${dataUser.zipCode}</li>`) : null;
	dataUser.billNr ? container.append(`<li>Numer paragonu: ${dataUser.billNr}</li>`) : null;
	dataUser.billDate ? container.append(`<li>Data paragonu: ${dataUser.billDate}</li>`) : null;
	dataUser.billNip ? container.append(`<li>NIP: ${dataUser.billNip}</li>`) : null;
	dataUser.birthDay ? container.append(`<li>Data urodzenia: ${dataUser.birthDay}</li>`) : null;
	dataUser.identityDocumentType ? container.append(`<li>Rodzaj dokumentu tożsamości: ${dataUser.identityDocumentType}</li>`) : null;
	dataUser.identityDocumentNr ? container.append(`<li>Numer dokumentu tożsamości: ${dataUser.identityDocumentNr}</li>`) : null;
	dataUser.pesel ? container.append(`<li>PESEL: ${dataUser.pesel}</li>`) : null;
	dataUser.citizenship ? container.append(`<li>Obywatelstwo: ${dataUser.citizenship}</li>`) : null;
	dataUser.street2 ? container.append(`<li>Adres 2: ${dataUser.street2}</li>`) : null;
	dataUser.streetNr2 ? container.append(`<li>Numer domu 2: ${dataUser.streetNr2}</li>`) : null;
	dataUser.apartmentNr2 ? container.append(`<li>Numer lokalu 2: ${dataUser.apartmentNr2}</li>`) : null;
	dataUser.city2 ? container.append(`<li>Miejscowość 2: ${dataUser.city2}</li>`) : null;
	dataUser.zipCode2 ? container.append(`<li>Kod pocztowy 2: ${dataUser.zipCode2}</li>`) : null;
	// dataUser.uuid ? container.append(`<li>UIC: ${dataUser.uuid}</li>`) : null;
	dataUser.pdf ? $(`${modal} #print`).attr('href', `${dataUser.pdf}`) : null;
}

$(form.contact).on('submit-success', function() {
	let modal = new bootstrap.Modal(`#modal-form-contact`, {});
	modal.show();
});

if (form.code) {
	form.code.addEventListener('submit', async function(e) {
		e.preventDefault();
		let formData = new FormData(this);
		if ($(this).data('recaptcha')) {
			formData.set('recaptcha', await getRecaptcha());
		}
		$.ajax({
			url: '/sprawdz-kod',
			type: 'POST',
			data: formData,
			processData: false,
			contentType: false,
			success: function(response) {
				if (response.status === 'ok') {
					form.code.classList.add('d-none');
					form.application.classList.remove('d-none');
					document.querySelector(`.form-type-radio`).classList.add('d-none');
					document.querySelector('form#form-application .billNr-form-data').classList.add('d-none');
					document.querySelector('form#form-application .billNr-form-data')?.setAttribute('disabled', true);
					document.querySelector(`form#form-application input[name="personal_data[billDate]"]`).removeAttribute('required');
					document.querySelector(`form#form-application input[name="personal_data[billNip]"]`).removeAttribute('required');
					document.querySelector(`form#form-application input[name="personal_data[billNr]"]`).removeAttribute('required');
					document.querySelector(`form#form-application input[name="code"]`).value = response.code;
					document.querySelector(`form#form-application span#code`).textContent = response.code;
				}
				if (response.error) {
					showFormError(`#form-code`, response.error);
				}
			},
			error: function(response) {
				console.log(response);
			}
		});
	});
}

if (form.bill) {
	form.bill.addEventListener('submit', async function(e) {
		form.bill?.classList.add('d-none');
		form.application?.classList.remove('d-none');
		document.querySelector(`.form-type-radio`)?.classList.add('d-none');
		const billNr = document.getElementById('personal_data_billNr')?.value;
		document.querySelector('form#form-application .billNr-form-data')?.classList.remove('d-none');
		document.querySelector('form#form-application .billNr-form-data')?.removeAttribute('disabled');
		document.querySelector(`form#form-application input[name="code"]`)?.classList.add('d-none');
		document.querySelector(`form#form-application input[name="personal_data[billDate]"]`)?.setAttribute('required', true);
		document.querySelector(`form#form-application input[name="personal_data[billNip]"]`)?.setAttribute('required', true);
		document.querySelector(`form#form-application input[name="personal_data[billNr]"]`)?.setAttribute('required', true);
		document.querySelector(`form#form-application input[name="personal_data[billNr]"]`).value = billNr;
		document.querySelector(`form#form-application #billNr`).textContent = billNr;
	});
}

$(form.application).on('submit-success', function(event, data) {
	try {
		fetch(`/api/${data.uuid}.json`)
		.then(response => response.json())
		.then(dataUser => {
			let modal = `#modal-prize-loss`;
			if (dataUser.prizeType === 'instant' || dataUser.prizeType === 'main') {
				modal = `#modal-prize-instant`;
				apiPersonalData(dataUser, modal);
			} else if (dataUser.prizeType === 'loss') {
				modal = `#modal-prize-loss`;
				apiPersonalData(dataUser, modal);
			}
			modal = new bootstrap.Modal(modal, {});
			modal.show();
			form.application.classList.add('d-none');
			checkFormType();
			document.querySelector(`.form-type-radio`).classList.remove('d-none');
			document.querySelector(`form#form-application input[name="code"]`).value = null;
			document.querySelector(`form#form-application #billNr`).textContent = null;
			document.querySelector('form#form-application .billNr-form-data')?.setAttribute('disabled', true);
			document.querySelector(`form#form-application input[name="personal_data[billNr]"]`).value = '';
		});
	} catch (e) {
		console.log(e);
	}
});

$(form.winner).on('submit-success', function(event, data) {
	try {
		fetch(`/api/${data.uuid}.json`)
		.then(response => response.json())
		.then(dataUser => {
			console.log(dataUser);
			let modal = `#modal-form-winner`;
			apiPersonalData(dataUser, modal);
		});
	}
	catch (e) {
		console.log(e);
	} finally {
		let modal = new bootstrap.Modal(`#modal-form-winner`, {});
		modal.show();
		modal._element.addEventListener('hidden.bs.modal', function() {
			window.location.href = '/';
		});
	}
});

$(function () {
    $(function () {
        if ($('body').hasClass('after-age-gate')) {
            let modal = new bootstrap.Modal(`#modal-more-prizes`, {});
            modal.show();
        }
    });
});

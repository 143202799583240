export const checkFormType = () => {
	let checkedValueID = document.querySelector('input[name="formType"]:checked')?.id;

	if (checkedValueID === 'form_type_code') {
		document.querySelector('form#form-code')?.classList.remove('d-none');
		document.querySelector('form#form-bill')?.classList.add('d-none');
		document.querySelector('form#form-application .billNr-form-data')?.setAttribute('disabled', true);
		document.querySelectorAll('.form-type-code').forEach((element) => {
			element.classList.remove('d-none');
		});
		document.querySelectorAll('.form-type-bill').forEach((element) => {
			element.classList.add('d-none');
		});
	} else if (checkedValueID === 'form_type_bill') {
		document.querySelector('form#form-code')?.classList.add('d-none');
		document.querySelector('form#form-bill')?.classList.remove('d-none');
		document.querySelector('form#form-application .billNr-form-data')?.removeAttribute('disabled');
		document.querySelectorAll('.form-type-code').forEach((element) => {
			element.classList.add('d-none');
		});
		document.querySelectorAll('.form-type-bill').forEach((element) => {
			element.classList.remove('d-none');
		});
	}
}

document.addEventListener('DOMContentLoaded', function () {
	checkFormType();

	document.querySelectorAll('input[name="formType"]').forEach((input) => {
		input.addEventListener('change', function () {
			checkFormType();
		});
	});

	document.querySelectorAll('button[data-form-type-switch]').forEach((button) => {
		button.addEventListener('click', () => {
			document.querySelector('input[name="formType"]:not(:checked)').click();
			checkFormType();
		})
	})

	
	$('#personal_data_pesel_checkbox').on('change', function() {
		let checked = $(this).prop('checked');
		$('#personal_data_pesel').prop('disabled', checked);
		$('#personal_data_pesel').prop('required', !checked);
		$('#personal_data_pesel').val('');
		$('fieldset#fieldset-pesel').toggleClass('d-none').prop('disabled', !checked);
	});

});

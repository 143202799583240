import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


export const swiperWinnersBeersDates = new Swiper('.swiper-winners-beers-dates .swiper', {
	modules: [Navigation],
	loop: false,
	allowTouchMove: false,
	autoHeight: true,
	navigation: {
		nextEl: '.swiper-winners-beers-dates .btn-swiper-navigation-next',
		prevEl: '.swiper-winners-beers-dates .btn-swiper-navigation-prev',
	},
});

export const swiperWinnersBeersList = new Swiper('.swiper-winners-beers-lists .swiper', {
	modules: [Navigation],
	loop: false,
	allowTouchMove: false,
	autoHeight: true,
	navigation: {
		nextEl: '.swiper-winners-beers-dates .btn-swiper-navigation-next',
		prevEl: '.swiper-winners-beers-dates .btn-swiper-navigation-prev',
	},
});

new Swiper('.swiper-winners-bikes-dates .swiper', {
	modules: [Navigation],
	loop: false,
	allowTouchMove: false,
	autoHeight: true,
	navigation: {
		nextEl: '.swiper-winners-bikes-dates .btn-swiper-navigation-next',
		prevEl: '.swiper-winners-bikes-dates .btn-swiper-navigation-prev',
	},
});

new Swiper('.swiper-winners-bikes-lists .swiper', {
	modules: [Navigation],
	loop: false,
	allowTouchMove: false,
	autoHeight: true,
	navigation: {
		nextEl: '.swiper-winners-bikes-dates .btn-swiper-navigation-next',
		prevEl: '.swiper-winners-bikes-dates .btn-swiper-navigation-prev',
	},
});
import $2 from "jquery";
window.$2 = $2;
import 'bootstrap5';
import 'popper.js';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.pl.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';
import { swiperWinnersBeersDates, swiperWinnersBeersList } from './swiper/winners.js'

$('.datepicker-purchase').datepicker({
	format: "yyyy-mm-dd",
	clearBtn: true,
	language: "pl",
	maxViewMode: 3,
	startDate: '2023-07-03',
	endDate: '2023-10-08',
});

$('.datepicker-birthday').datepicker({
	format: "yyyy-mm-dd",
	clearBtn: true,
	language: "pl",
	maxViewMode: 3,
	startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 120)),
	endDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
	defaultViewDate: {
		year: new Date().getFullYear() - 18,
		month: 0,
		day: 1
	},
});

$('#datepicker-winners-beers').datepicker({
	format: "yyyy-mm-dd",
	language: "pl",
	maxViewMode: 0,
});

let dates = [];
let swiperSlides = document.querySelectorAll('.swiper-winners-beers-dates .swiper-slide');
swiperSlides.forEach(function (slide) {
	dates.push(slide.dataset.date);
});

const formatDate = (date) => {
	if (!date) return;
	let dateArray = date.split('.').reverse();
	dateArray = dateArray.join('-');
	dateArray = `2023-${dateArray}`;
	return new Date(dateArray);
}

$('#datepicker-winners-beers').datepicker('setStartDate', formatDate(dates[0]));
$('#datepicker-winners-beers').datepicker('setEndDate', formatDate(dates[dates.length - 1]));
$('#datepicker-winners-beers').datepicker('setDate', formatDate(dates[0]));

$('#datepicker-winners-beers').datepicker().on('changeDate', function (e) {
	let date = e.format(0, 'dd.mm');
	let slide;
	let slideId;
	swiperWinnersBeersDates.slides.forEach(function (element, index) {
		if (element.dataset.date === date) {
			slide = element;
			slideId = index;
		}
	});
	if (!slide) {
		swiperWinnersBeersDates.slideTo(0);
		swiperWinnersBeersList.slideTo(0);
		$('#datepicker-winners-beers').datepicker('setDate', formatDate(dates[0]));
		return;
	}
	swiperWinnersBeersDates.slideTo(slideId);
	swiperWinnersBeersList.slideTo(slideId);
});
import Swiper, { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const swiperProductsCodeElement = document.querySelector('.swiper-products-code');
const swiperProductsBillElement = document.querySelector('.swiper-products-bill');

if (swiperProductsCodeElement) {
	new Swiper('.swiper-products-code', {
		modules: [Navigation, Autoplay],
		loop: true,
		autoHeight: true,
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: '#btn-swiper-products-code-navigation-next',
			prevEl: '#btn-swiper-products-code-navigation-prev',
		},
	});
}

if (swiperProductsBillElement) {
	new Swiper('.swiper-products-bill', {
		modules: [Navigation, Autoplay],
		loop: true,
		autoHeight: true,
		autoplay: {
			delay: 3000,
		},
		navigation: {

			nextEl: '#btn-swiper-products-bill-navigation-next',
			prevEl: '#btn-swiper-products-bill-navigation-prev',
		},
	});
}